import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  apiUrl = environment.apiUrlPaV1;
  apiUrlLsV1 = environment.apiUrlLsV1;

  constructor(
    private http: HttpClient
  ) { }

  createPayment(data, discounted_project_id = false) {
    console.log(discounted_project_id)
    let httpOptions = {}
    if(discounted_project_id){
			httpOptions = {
	      headers: new HttpHeaders({
	        'discounted_project_id': 'true'
	      })
	    }
    }
    return this.http
      .post(this.apiUrl + 'payments', data, httpOptions)
      .toPromise();
  }

  getBurnRatio(burn_ratio_ids='') {
    // const segId = '10329bc2-df89-4aeb-a311-ba03b9bbd234';
    return burn_ratio_ids ? 
      this.http
      .get(this.apiUrlLsV1 + 'burn_rules/burn_ratio', {
        params: {
          // segment: segId,
          // burn_options: 'memberships'
          burn_ratio_ids
        }
      })
      .toPromise() :
      this.http
      .get(this.apiUrlLsV1 + 'burn_rules/burn_ratio', {
        params: {
          // segment: segId,
          // burn_options: 'memberships'
          // burn_ratio_ids
        }
      })
      .toPromise() ;
  }

  getCardDetails(number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-API-Client': 'test_client',
        'X-API-Client-Token': '12345',
      })
    }
    // let url = environment.rewardsHubApiUrl + 'payments/get_card_details?card_num=' + number;
    // return this.http.get(url, httpOptions)
    let url = this.apiUrl + 'payments/get_card_details';
    return this.http.post(url, { card_num: number }, httpOptions)
  }

  getPaymentDetails(bookingId) {
    return this.http
      .get(this.apiUrl + 'payments/' + bookingId)
      .toPromise();
  }

  saveUserCard(data) {
    return this.http
      .post(this.apiUrl + 'payments/save_user_card', data)
      .toPromise();
  }

  getUserCards() {
    return this.http
      .post(this.apiUrl + 'payments/get_user_cards', {})
      .toPromise();
  }


  resendPaymentLink(unique_id) {
    let body = {
      booking_id: unique_id,
    }
    return this.http
      .post(this.apiUrl + `payments/send_payment_link`, body)
  }
}
