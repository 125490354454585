import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { PaymentService } from '../../payment/service/payment.service';
import { map, catchError } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class GiftcardService {
  allGiftcards = [];
  allMemberships = [];
  progress: boolean = true;
  membershipProgress: boolean = true;
  rewardPoints;
  userCardDetails;

  apiUrl = environment.apiBookingServiceUrl;
  offerPathV2 = environment.offerPathV2;

  private cartItems = new BehaviorSubject<any>('');
  public cartItems$ = this.cartItems.asObservable();

  private isGiftCardRoute = new BehaviorSubject<any>(false);
  public giftCardRoute$ = this.isGiftCardRoute.asObservable();

  burnRatioData;

  constructor(
    private http: HttpClient,
    private paymentService: PaymentService
  ) {
    // this.getAllGiftCards();
  }

  updateCartData(cartData) {
    this.cartItems.next(cartData);
  }

  updateGCRoute(isGCRoute) {
    this.isGiftCardRoute.next(isGCRoute);
  }

  getCategories(benefit_type) {
    return this.http
      .get(this.offerPathV2 + `/categories?benefit_type=${benefit_type}`)
      .toPromise();
  }

  getMemberships(pageNo, itemsPerPage) {
    return this.http
      .get(this.offerPathV2 + '/memberships' + ('?page=' + pageNo) + ('&per_page=' + itemsPerPage))
      .toPromise();
  }

  getMembershipDetails(id) {
    return this.http
      .get(this.offerPathV2 + `/memberships/${id}`)
      .toPromise();
  }

  getAllMemberships() {
    return new Promise<void>((resolve) => {
      this.getMemberships(1, 500).then((res: any) => {
        this.allMemberships = res.data;
        this.membershipProgress = false;
        resolve();
      });
    });
  }

  getGiftcards(pageNo, itemsPerPage, popular?: boolean):Promise<any> {
    return this.http
      .get(this.offerPathV2 + '/gift_cards' + ('?page=' + pageNo) + ('&per_page=' + itemsPerPage) + (popular ? ('&popular=' + popular) : ''))
      .toPromise();
  }

  getGiftcardDetails(id) {
    return this.http
      .get(this.offerPathV2 + `/gift_cards/${id}`)
      .toPromise();
  }

  getAllGiftCards() {
    return new Promise<void>((resolve) => {
      this.getGiftcards(1, 500).then((res: any) => {
        this.allGiftcards = res.data;
        this.progress = false;
        resolve();
      });
    });
  }
  getGiftcardsItems() {
    let ids = ['1a51953e-6439-4806-98c6-0aebeb0a617b', '19b069c0-cdd6-4979-9a4c-d9c1602ffe5b']
		return this.http.get<any>(this.offerPathV2 + '/gift_cards' + ('?page=1') + ('&per_page=' + 500), {})
			.pipe(
				map(res => res || res),
				catchError(error => throwError(error))
			);
	}
  deleteFromCart(deleteData, bookingId) {
    return this.http
      .post(this.apiUrl + `carts/remove_item`, deleteData).toPromise();
  }

  clearCartItems() {
    const data = {
      "category": "GiftCard"
    };
    return this.http.post(this.apiUrl + `carts/clear_cart`, data).toPromise();
  }

  pushToCart(cartParams) {
    return this.http
      .post(
        this.apiUrl + `carts/add_items`,
        cartParams
      );
  }

  getGiftcardsInCart() {
    return this.http
      .get(
        this.apiUrl + `carts/list_items?category=GiftCard`
      ).toPromise();
  }

  checkout() {
    const data = {
      "category": "GiftCard"
    }
    return this.http
      .post(
        this.apiUrl + `carts/checkout`, data
      );
  }

  createMembershipBooking(data) {
    return this.http.post(
      this.apiUrl + 'memberships/create_booking', data
    )
  }

  updateMembershipBooking(data) {
    return this.http.post(
      this.apiUrl + 'memberships/update_booking', data
    ).toPromise();
  }

  addUserData(data) {
    return this.http
      .post(
        this.apiUrl + `gift_cards/add_user_data`, data
      ).toPromise();
  }

  getBookingById(id) {
    return this.http
      .get(this.apiUrl + `bookings/${id}`)
      .toPromise();
  }

  searchGiftcards(searchquery) {
    return this.http
      .get(this.offerPathV2 + '/gift_cards' + ('?search=' + searchquery));
  }

  getBurnRatio(burnRatioId='') {
    return new Promise<void>((resolve, reject) => {
      // if (this.burnRatioData) { resolve(this.burnRatioData); }
      // else {
        this.fetchBurnRatio(burnRatioId).then((res) => {
          this.burnRatioData = res;
          resolve(this.burnRatioData);
        })
      }
    // }
    )
  }

  fetchBurnRatio(burnRatioId) {
    return this.paymentService.getBurnRatio(burnRatioId);
  }

  getAllBookings() {
    return this.http
      .get(environment.apiBookingServiceUrl + `/bookings?type=GiftCardBooking`)
      .toPromise();
  }

  resendGiftcards(bookingId) {
    return this.http.get(environment.apiBookingServiceUrl + "/gift_cards/resend_gift_card?gift_card_order_id=" + bookingId).toPromise()
  }

  cancelBookingRequest(bookingUniqueId, reason) {
    return this.http.post(environment.stagingUrl + 'users/bookings/request_cancellation', {
      'booking_unique_id': bookingUniqueId,
      'reason': reason
    }).toPromise()
  }

  getBookingsById(id) {
    return this.http
      .get(environment.apiBookingServiceUrl + `/bookings/${id}`)
      .toPromise();
  }

}
